import { Auth0Lock } from 'auth0-lock';

export interface LoginFormAppConfig {
    auth0: { clientID: string; domain: string; callbackUrl: string; connections?: string[] };
    redirectUrl?: string;
}

export interface LoginFormUrlSearchParams {
    prefill: Auth0LockConstructorOptions['prefill'];
    error?: string;
    redirectUrl?: string;
    initialScreen: Exclude<Auth0LockShowOptions['initialScreen'], 'signUp'>;
}

export type LoginFormConfig = LoginFormUrlSearchParams & LoginFormAppConfig;

const getErrorMessageFromSearchParams = (params: URLSearchParams): string | undefined => {
    try {
        if (!params.get('error')) return;
        let message = params.get('error_description');
        message ??= 'Unknown error, please contact support: support@42technologies.com';
        message = decodeURIComponent(message);
        return message;
    } catch (error) {
        console.error(error);
        return;
    }
};

// This will be used by the auth proxy to redirect after login
const getRedirectUrlFromSearchParams = (params: URLSearchParams): string | undefined => {
    const result = params.get('redirect_url') || params.get('redirectUrl') || params.get('redirectURL');
    return result ?? undefined;
};

const getInitialScreenFromSearchParams = (params: URLSearchParams) => {
    const isValid = (x: unknown): x is LoginFormUrlSearchParams['initialScreen'] => {
        return typeof x === 'string' && ['login', 'forgotPassword'].includes(x);
    };
    const value = params.get('initial_screen') || params.get('initialScreen');
    return isValid(value) ? value : undefined;
};

const getPrefillFromSearchParams = (params: URLSearchParams): LoginFormUrlSearchParams['prefill'] => {
    const email = params.get('login_hint');
    if (!email) return {};
    return { email };
};

const getPrefillFromLocalStorage = () => {
    const email = localStorage.getItem('42.login-form.auth0lock.prefill.email');
    if (!email) return {};
    return { email };
};

const getAppConfig = async (): Promise<LoginFormAppConfig> => {
    const response = await fetch('/config/config.json');
    if (response.ok) return await response.json();
    console.error(response);
    throw new Error('Could not get config due to error.');
};

const getRedirectUrl = (config: LoginFormAppConfig, redirectUrl?: string) => {
    try {
        if (!config.auth0.callbackUrl) {
            console.error('No callback URL configured.');
            return;
        }
        const authUrl = new URL(config.auth0.callbackUrl);
        // if we have one, we add it to the auth0 callback url, the auth proxy will send a redirect
        if (redirectUrl) authUrl.searchParams.set('redirect_url', redirectUrl);
        return authUrl.toString();
    } catch (error) {
        console.error(error);
        return;
    }
};

const getConfig = async (window: Window): Promise<LoginFormConfig> => {
    const appConfig = await getAppConfig();
    const params = new URL(window.location.href).searchParams;
    const redirectUrl = getRedirectUrl(appConfig, getRedirectUrlFromSearchParams(params));

    return {
        auth0: appConfig.auth0,
        prefill: { ...getPrefillFromLocalStorage(), ...getPrefillFromSearchParams(params) },
        initialScreen: getInitialScreenFromSearchParams(params),
        redirectUrl: redirectUrl,
        error: getErrorMessageFromSearchParams(params),
    };
};

export const createLoginForm = async (window: Window, logoUrl: string) => {
    const config = await getConfig(window);
    console.log('Login form config:', config);

    const lock = new Auth0Lock(config.auth0.clientID, config.auth0.domain, {
        container: 'login-form',
        avatar: null,
        closable: false,
        allowSignUp: false,
        rememberLastLogin: true,
        allowAutocomplete: false,
        allowShowPassword: true,
        usernameStyle: 'email',
        prefill: config.prefill,
        allowedConnections: config.auth0.connections,
        configurationBaseUrl: 'https://cdn.auth0.com',
        theme: {
            logo: logoUrl,
            primaryColor: '#242a33',
        },
        auth: {
            responseType: 'code',
            redirectUrl: config.redirectUrl,
            sso: false,
        },
    });

    lock.show({
        initialScreen: config.initialScreen ?? 'login',
        flashMessage: config.error ? { type: 'error', text: config.error } : undefined,
    });
};
